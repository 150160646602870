import React from "react";
import PreHeader from "../../Components/Preheader/PreHeader";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Outlet } from "react-router-dom";

function PageWrapper() {
  return (
    <div>
      <PreHeader />
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default PageWrapper;
