import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import centerimg from "../../../../assets/images/centerimg.jpg"
import courseimg from "../../../../assets/images/course.png"
import course1img from "../../../../assets/images/course1.png"
import Carousel from 'react-bootstrap/Carousel';
import campimg from "../../../../assets/images/camp.jpg"
import bgimg from "../../../../assets/images/bg.jpg"

import cssClasses from "./AboutUs.module.css";



function AboutUs() {
  return (
    <>
      <div className={`content-container ${cssClasses.aboutCenterWrapper}`}>
        <div>
          <img style={{ width: "100%" }} alt="centerimg" src={centerimg} />
        </div>
        <div>
          <h2>ABOUT DHANORI CENTER </h2>
          <p style={{ color: "#5e6d75" }}>
            The Brahma Kumaris Rajyoga Bhavan is a unique learning centre of the
            Brahma Kumaris, that provides basic meditation training as well as
            higher education in the art of living. It was inaugurated by the
            then Honorable Deputy Chief Minister of Maharashtra Mr. Ajit Pawar
            on 13th March, 2020. It is developed in collaboration with Pune
            Municipal Corporation for the betterment of society. Our main focus
            areas are: <br></br>• Empowering individuals <br></br>• Higher order
            values of life <br></br>• Stress and anxiety free lives<br></br> •
            Relationship counselling<br></br> • De-addiction
            <br></br> • Training in Rajyoga Meditation
          </p>
        </div>
      </div>

      <div style={{ marginTop: "4rem" }}>
        <h2
          style={{
            textAlign: "center",
            backgroundColor: "#2a709c",
            padding: "0.6rem"
          }}
        >
          ABOUT BRAHMA KUMARIS
        </h2>
        <p
          className="content-container"
          style={{ color: "#5e6d75", marginTop: "20px" }}
        >
          Prajapita Brahma Kumaris Ishwariya Vishwa Vidyalaya (also called
          Brahma Kumaris) is an international non-governmental spiritual
          organisation with its headquarters located at Mount Abu, Rajasthan. We
          are a spiritual institution focused on self-transformation through
          spiritual education and reflective practices. We focus on
          self-transformation by re-discovering and strengthening human
          potential. This is done by providing a deep and clear understanding of
          self, God and human existence. The primary teachings at Brahma Kumaris
          is Raj Yoga meditation. Raj Yoga meditation is one of the most
          effective forms of meditation to help replenish mental and emotional
          energy enabling individuals to create new attitudes and responses to
          life. It empowers individuals with a clear spiritual understanding of
          self and connection with the Supreme Soul. We offer various other
          courses for dealing with issues such as stress, failure in
          relationships, work-life imbalance etc. All these courses are offered
          free of cost and are based on the principles of Raj Yoga meditation.
        </p>

        <div>
          <img
            src={courseimg}
            width="100%"
            height="350"
            style={{ marginTop: "50px" }}
            alt=""
          />

            <div className="content-container">
              <img
                src={course1img}
                width="100%"
                style={{ marginTop: "50px" ,marginBottom:"100px"}}
                alt=""
              />
            </div>
        </div>

        
        
      </div>
    </>
  );
}

export default AboutUs;
