import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import facebook from "../../../../assets/images/facebook.png";
import instagram from "../../../../assets/images/instagram.png";
import linkedin from "../../../../assets/images/linkedin.png";
import twitter from "../../../../assets/images/twitter.png";
import whatsapp from "../../../../assets/images/whatsapp.png";
import cssClasses from "./Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  const icon = [
    {img: facebook, link: "https://www.facebook.com/"},
    {img: instagram, link: "https://www.instagram.com/"},
    {img: linkedin, link: "https://www.linkedin.com/feed/"},
    {img: twitter, link: "https://twitter.com/?lang=en"},
    {img: whatsapp, link: "https://www.whatsapp.com/"}
  ];

  return (
    <footer className="App-footer">
      <div style={{ width: "min(110rem, 80%)" }}>
        <div
          style={{
            color: "white",
            borderBottom: "1px solid white",
            marginBottom: "1rem",
          }}
        >
          <h1 style={{ color: "#3f9ba6" }}>Soul Sustenance</h1>
          <p style={{ color: "#bcd0d1" }}>
            Start your day with a breeze of positivity and stay motivated with
            these daily affirmations
          </p>
        </div>

        <Container>
          <Row>
            <Col>
              <b>Meditation</b>
            </Col>
            <Col>
              <b>More</b>
            </Col>
            <Col>
              <b>Exclusive</b>
            </Col>
          </Row>

          <Row>
            <Col>
              {" "}
              <Link
                to="https://www.brahmakumaris.com/rajyoga-meditation/"
                target="_blank"
              >
                Rajyoga Meditation{" "}
              </Link>
            </Col>
            <Col>
              <Link to="/about">
                About Us{" "}
              </Link>
            </Col>
            <Col>
              <Link
                to="https://www.brahmakumaris.com/brahma-baba/"
                target="_blank"
              >
                Brahma Baba
              </Link>
            </Col>
          </Row>

          <Row>
            <Col>
              {" "}
              <Link
                to="https://www.brahmakumaris.com/spiritual-knowledge/"
                target="_blank"
              >
                Spiritual Knowledge
              </Link>
            </Col>
            <Col>
              <Link to="/gallery">
                Gallery
              </Link>
            </Col>
            <Col>
              <Link to="https://www.brahmakumaris.com/mamma/" target="_blank">
                Mumma
              </Link>
            </Col>
          </Row>

          <Row>
            <Col>
              {" "}
              <Link to="https://www.brahmakumaris.com/magazine/" target="_blank">
                Magzine
              </Link>
            </Col>
            <Col>
              <Link to="/contact">
                Contact Us{" "}
              </Link>
            </Col>
            <Col>.</Col>
          </Row>
        </Container>

        <div className={cssClasses?.socialMediaWrapper}>
          {icon?.map((iconPath, iconIndex) => (
            <img onClick={() => window.open(iconPath.link, "_blank")} className={cssClasses?.iconImage} key={`social-ico-${iconIndex}`} src={iconPath.img} alt="" />
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
