import React from "react";
import cssClasses from "./home.module.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { Swiper, SwiperSlide } from "swiper/react";
import {
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Autoplay,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";

import centerImg from "../../../../assets/images/centerimg.jpg";

import reltionImg from "../../../../assets/images/reltion.jpg";
import stressImg from "../../../../assets/images/stress.jpg";
import positiveImg from "../../../../assets/images/positive.jpg";
import campImg from "../../../../assets/images/camp.jpg";
import sus1Img from "../../../../assets/images/sus1.jpg";
import sus2Img from "../../../../assets/images/sus2.jpg";
import sus3Img from "../../../../assets/images/sus3.jpg";

import reltion1Img from "../../../../assets/images/reltion1.jpg";
import positive1Img from "../../../../assets/images/positive1.jpg";
import stress1Img from "../../../../assets/images/stress1.png";
import camp1Img from "../../../../assets/images/camp1.png";
import sus5Img from "../../../../assets/images/sus5.png";
import sus6Img from "../../../../assets/images/sus6.png";
import sus7Img from "../../../../assets/images/sus7.png";

import comma1Img from "../../../../assets/images/comma1.png";

import Carousel from 'react-bootstrap/Carousel';
import M2img from "../../../../assets/images/M2.jpg"
import m5img from "../../../../assets/images/m5.avif"
import m6img from "../../../../assets/images/m6.avif"

const CarouselImages = [M2img,m5img,m6img];

function Home() {
  const courses = [
    {
      img: reltionImg,
      title: reltion1Img,
    },
    {
      img: positiveImg,
      title: positive1Img,
    },
    {
      img: stressImg,
      title: stress1Img,
    },
    {
      img: campImg,
      title: camp1Img,
    },
  ];

  const testimonialsImages = [comma1Img, comma1Img, comma1Img];

  const dailySentences = [
    {
      redirectUrl:
        "https://www.brahmakumaris.com/soul-sustenance/5-new-year-promises-to-make-to-yourself-2/",
      img: sus5Img,
    },
    {
      redirectUrl:
        "https://www.brahmakumaris.com/soul-sustenance/ss-hindi/soch-mein-khushi-anubhav-karein-naaki-duniyavi-cheezon-se/",
      img: sus6Img,
    },
    {
      redirectUrl:
        "https://www.brahmakumaris.com/soul-sustenance/ss-hindi/sakaratmak-vicharo-se-sakaratmak-swasthya-banayein/",
      img: sus7Img,
    },
  ];

  

  return (
    <>
      <div style={{marginTop:"2px"}}>
      <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={M2img} width="100%" height="700px"         
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={m5img} width="100%" height="700px"
          alt="Second slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={m6img} width="100%" height="700px"
          alt="Third slide"
        />
        
      </Carousel.Item>
    </Carousel>
      
        
        <div className={`content-container ${cssClasses.oasisPeaceWrapper}`}>
          <div className={cssClasses.oasisPeaceContainer}>
            <div className={cssClasses.leftSideTextContainer}>
              <h3 style={{ color: "#b82130" }}>An Oasis of Peace</h3>
              <h1>Rajyoga Bhawan Dhanori Center Pune </h1>
              <p style={{ color: "#6c8f9e", margin: 0 }}>
                The Brahma Kumaris Rajyoga Bhavan is a unique learning centre of
                the Brahma Kumaris, that provides basic meditation training as
                well as higher education in the art of living. It was
                inaugurated by the then Honorable Deputy Chief Minister of
                Maharashtra Mr. Ajit Pawar on 13th March, 2020. It is developed
                in collaboration with Pune Municipal Corporation for the
                betterment of society. Our main focus areas are: <br></br>•
                Empowering individuals <br></br>• Higher order values of life{" "}
                <br></br>• Stress and anxiety free lives. <br></br>Brahma
                Kumaris was founded by Dada Lekhraj Kripalani, a retired Indian
                businessman, in 1937 in Hyderabad, a city now in Pakistan. His
                spiritual name is Prajapita Brahma and he is lovingly called
                Brahma Baba. After experiencing a series of visions in 1936, he
                was inspired to create a school where the principles and
                practices of a virtuous and meditative life could be taught. The
                original name was 'Om Mandali'. It consisted of a handful of
                men, women and children, many of whom decided to stay together
                as a community.
              </p>
            </div>

            <div className={cssClasses.rightSideImageContainer}>
              <img alt="centerimg" src={centerImg}></img>
            </div>
          </div>
        </div>

        <div className={`content-container`}>
          <div className={cssClasses.testimonialsWrapper}>
            <div>
              <h1
                style={{
                  textAlign: "center",
                  color: "#4388a3",
                }}
              >
                <b>Testimonials</b> Of Bliss
              </h1>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "5px",
                  margin: "5rem",
                 
                }}
              >
                Rajyogi Seniors, Seekers, and Participants Open Their Hearts,
                Sharing the Profound Meditative Experiences at Rajyoga Bhawan
                Dhanori Center . Be Inspired by Their Tales of Spiritual
                Awakening and Find Your Path to Harmony in the Tranquil Oasis of
                Brahma Kumaris
                <br></br>Join Us at Rajyoga Bhawan Dhanori Center, where All Are
                Invited to Embrace the Meditative Lifestyle and Awaken the Inner
                Self
              </p>
            </div>
            
                    <div>
                     
                    <Container>
       <Row className={cssClasses.testimonialsCardWrapper}>
        <Col  className={cssClasses.testimonialsCardContainer}><img src={comma1Img} style={{width:"40px" , height:"40px"}}></img>
         <h3 style={{textAlign:"center",marginTop:"20px"}}>Ashish Sahu</h3>
                <p style={{textAlign:"center",marginTop:"20px"}}>The Brahma Kumaris Center
                 is very peaceful and spiritual.I usually go on weekends.Arun bhai and Shaila
                  Didi are very loving and helpful.
                Please visit and feel the positive energy and blessings.Om Shanti</p> 
                </Col>


        <Col  className={cssClasses.testimonialsCardContainer}><img src={comma1Img} style={{width:"40px" , height:"40px"}}></img>
         <h3 style={{textAlign:"center",marginTop:"20px"}}>Saurav Suman</h3>
                <p style={{textAlign:"center",marginTop:"20px"}}>Ecstatic experience.
                 A tranquil place where you realise that you have all the keys. Keys of happiness,
                  joy, peace, love and wisdom.Highly recommended to those who want to spend some
                   time with themselves in solitude.</p>
                    </Col>


        <Col  className={cssClasses.testimonialsCardContainer}><img src={comma1Img} style={{width:"40px" , height:"40px"}}></img>
        <h3 style={{textAlign:"center",marginTop:"20px"}}>Bhavi Jain</h3>
                <p style={{textAlign:"center",marginTop:"20px"}}>I have recently joined Brahma kumaris centre for meditation.
                I m enjoying the session and learning how to meditate.How to connect with supreme energy.
                 I have seen some positive chages in my life.</p>
                  </Col>
      </Row>
    </Container>

    </div>                                         
               </div>

          <div className={cssClasses.coursesWrapper}>
            <h1
              style={{
                color: "#4388a3",
                marginBottom: "2.5rem",
                transition:"1s",
                marginTop:"2.5rem",
                textAlign: "center",
              }}
            >
              <b>Courses</b> We Offered
            </h1>

            <div className={cssClasses.coursesCardWrapper}>
              <div className={cssClasses.coursesCard}>
                <img src={reltionImg} alt="Cinque Terre"></img>
                <div class="App-desc">
                  <b>Harmony In Relationship</b>
                </div>
              </div>
              <div className={cssClasses.coursesCard}>
                <img src={stressImg} alt="Forest"></img>
                <div class="App-desc">
                  <b>Stress Free Living </b>
                </div>
              </div>
              <div className={cssClasses.coursesCard}>
                <img src={positiveImg} alt="Northern Lights"></img>
                <div class="App-desc">
                  <b>Positive Thinking</b>
                </div>
              </div>
              <div className={cssClasses.coursesCard}>
                <img src={campImg} alt="Mountains"></img>
                <div class="App-desc">
                  <b>Summer Camp</b>
                </div>
              </div>
            </div>
          </div>

          <div className={cssClasses.dailySustenanceCardsWrapper}>
            <h2
              style={{
                textAlign: "center",
                color: "#4388a3",
                flex: "100%",
              }}
            >
              <b>Daily</b> Sustenance
            </h2>
            <div className={`App-daily ${cssClasses.dailySustenanceCard}`}>
              <a
                target="_blank"
                href="https://www.brahmakumaris.com/soul-sustenance/time-management-in-our-daily-lives-part-2/"
              >
                <img src={sus1Img} alt="Cinque Terre"></img>
              </a>
              <div class="App-desc">
                <b>Time Management In Our Daily Lives (Part 2)</b>
                <p>
                  Whenever we are very busy, <br></br>we need to take a break
                  for a<br></br> minute or two and talk to ourselves and{" "}
                  <br></br>give ourselves a few positive thoughts. <br></br>In
                  this way, the negative thoughts will be <br></br>replaced and
                  reduced in your mind.
                </p>
              </div>
            </div>

            <div className={`App-daily ${cssClasses.dailySustenanceCard}`}>
              <a
                target="_blank"
                href="https://www.brahmakumaris.com/soul-sustenance/ss-hindi/har-kadam-par-apne-values-ko-thame-rahein/"
              >
                <img src={sus2Img} alt="Forest"></img>
              </a>
              <div class="App-desc">
                <b>हर कदम पर अपने वेल्यूज को थामे रहें</b>
                <p>
                  जीवन में हमें अपना बैटर वर्जन<brt></brt> बनने के लिए, बहुत कुछ{" "}
                  <br></br>हासिल करने की ज़रूरत नहीं होती है,<br></br> लेकिन यह
                  सुनिश्चित करना ज़रूरी होता है कि,<br></br> हमारे पास जो भी
                  क्वॉलिटीज हैं;<br></br> हम उन्हें खोने न दें।<br></br>आमतौर पर
                  हमारा व्यवहार लोगों द्वारा{" "}
                </p>
              </div>
            </div>

            <div className={`App-daily ${cssClasses.dailySustenanceCard}`}>
              <a
                target="_blank"
                href="https://www.brahmakumaris.com/soul-sustenance/the-mind-and-its-creations-2/"
              >
                <img src={sus3Img} alt="Mountains"></img>
              </a>
              <div class="App-desc">
                <b>Time Management In Our Daily Lives (Part 1)</b>
                <p>
                  Living a life full of work pressures<br></br> and deadlines in
                  the present world <br></br>of busy lifestyles and <br></br>
                  challenging relationships can be very <br></br>stressful for
                  many people at times.<br></br> What should one do to remain
                  free
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
