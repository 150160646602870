import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import cssClasses from "./Header.module.css";
import titleLogo from "../../../../assets/images/title-logo.png"

function Header() {
  const navigate = useNavigate();
  function GotoPage(path) {
    navigate(path);
  }

  return (
    <Navbar expand="lg" className={`bg-body-tertiary ${cssClasses.headerComponentWrapper}`}>
      <div className={`content-container ${cssClasses.headerWrapper}`}>
        <Navbar.Brand href="/">
          <img
            src={titleLogo}
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
            style={{ width: '15rem', height: '4rem' }}
          />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav" className={cssClasses.navLinkTextWrapper}>
          <Nav className={cssClasses.navLinkTextContainer}>
            <Nav.Link onClick={() => GotoPage("/")} className={cssClasses.navLinkText}>
              Home
            </Nav.Link>
            <Nav.Link onClick={() => GotoPage("about")} className={cssClasses.navLinkText}>
              About
            </Nav.Link>
            <Nav.Link onClick={() => GotoPage("events")} className={cssClasses.navLinkText}>
              Events
            </Nav.Link>
            <Nav.Link onClick={() => GotoPage("gallery")} className={cssClasses.navLinkText}>
              Gallery
            </Nav.Link>
            <Nav.Link onClick={() => GotoPage("contact")} className={cssClasses.navLinkText}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </div>
    </Navbar>
  );
}

export default Header;
