import React, { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import cssClasses from "./ContactUs.module.css";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import ImageLocation from "../../../../assets/images/location.png";
import ImageMail from "../../../../assets/images/mail.png";
import ImageContact from "../../../../assets/images/contact.png";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";

function ContactUs() {
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    city: "",
    state: "",
    email: "",
    message: "",
    queryType: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    city: "",
    state: "",
    email: "",
    message: "",
    queryType: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // const buttonIsDisable = () => {
  //   const f = isLoading || Object.keys(values).some((s) => !values(s));
  //   console.log("f -> ", f);
  //   return f;
  // };
  const buttonIsDisable = useMemo(() => {
    const f = isLoading || Object.keys(values).some((s) => !values[s]);
    // console.log("f -> ", f);
    return f;
  }, [values, isLoading]);

  async function submitForm(e) {
    // e.preventDefault();
    try {
      setIsLoading(true);
      let url =
        "https://brahma-kumaris-backend.onrender.com/ticket/create-ticket";
      const apiResponse = await axios.post(url, values);
      console.log("apiResponse: ", apiResponse);
      if (apiResponse?.data) {
        setValues({
          name: "",
          mobile: "",
          city: "",
          state: "",
          email: "",
          message: "",
          queryType: "",
        });
        setErrors({
          name: "",
          mobile: "",
          city: "",
          state: "",
          email: "",
          message: "",
          queryType: "",
        });
      }
      setIsLoading(false);
      toast.success("Your response submit successfully.");
    } catch (error) {
      console.error("error -> ", error);
      let err = error?.response?.data;
      if (err?.includes("invalid")) {
        toast.error("Please enter the valid values.");
      } else {
        toast.error("Something went wrong please try again.");
      }
      setIsLoading(false);
    }
  }

  function onInputChange(_val, _field) {
    // console.log(_val, _field);

    setValues((pre) => ({
      ...pre,
      [_field]: _val,
    }));
  }

  function isNameValid(_value = null) {
    if (_value === null) {
      _value = values.name;
    }
    let errorMessage = "";
    if (!_value) {
      errorMessage = "Please Enter name.";
    } else if (!/.{3,}/.test(_value)) {
      errorMessage = "Please enter at least three characters.";
    }

    errors.name = errorMessage;
    setErrors(errors);
  }
  function isPhoneValid(_value = null) {
    if (_value === null) {
      _value = values.mobile;
    }
    let errorMessage = "";
    if (!_value) {
      errorMessage = "Please Enter phone number.";
    } else if (!/\d{10}/.test(_value)) {
      errorMessage = "Please enter 10 digit number.";
    }

    errors.mobile = errorMessage;
    setErrors(errors);
  }
  function isEmailValid(_value = null) {
    if (_value === null) {
      _value = values.email;
    }
    let errorMessage = "";
    if (!_value) {
      errorMessage = "Please Enter email.";
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(_value)) {
      errorMessage = "Please enter valid email.";
    }

    errors.email = errorMessage;
    setErrors(errors);
  }

  function isCityValid(_value = null) {
    if (_value === null) {
      _value = values.city;
    }
    let errorMessage = "";
    if (!_value) {
      errorMessage = "Please Enter city.";
    }
    errors.city = errorMessage;
    setErrors(errors);
  }

  function isStateValid(_value = null) {
    if (_value === null) {
      _value = values.state;
    }
    let errorMessage = "";
    if (!_value) {
      errorMessage = "Please Enter state.";
    }
    errors.state = errorMessage;
    setErrors(errors);
  }

  function isQueryValid(_value = null) {
    if (_value === null) {
      _value = values.queryType;
    }
    let errorMessage = "";
    if (!_value) {
      errorMessage = "Please Select query.";
    }
    errors.queryType = errorMessage;
    setErrors(errors);
  }

  function isMessageValid(_value = null) {
    if (_value === null) {
      _value = values.message;
    }
    let errorMessage = "";
    if (!_value) {
      errorMessage = "Please Enter message.";
    }
    errors.message = errorMessage;
    setErrors(errors);
  }

  return (
    <div
      style={{ paddingTop: "2rem" }}
      className={`content-container ${cssClasses.pageWrapper}`}
    >
      <CardGroup className={`${cssClasses.firstBlock}`}>
        <Card style={{ minHeight: "350px" }}>
          <Card.Body>
            <Card.Text style={{ marginTop: "20px" }} as={"div"}>
              <h5 style={{ paddingBottom: "20px" }}>
                <img
                  src={ImageLocation}
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "20px",
                  }}
                ></img>
                <b> Address: </b>Survey no.19/4, Near polite Paradise, Next to
                MSEB office, Jakatnaka, Dhanori, Pune, Maharashtra 411015.
              </h5>
              <h5 style={{ paddingBottom: "20px" }}>
                <img
                  src={ImageContact}
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "20px",
                  }}
                ></img>
                <b>Contact Number: </b>+91-8975213990
              </h5>
              <h5 style={{ paddingBottom: "20px" }}>
                <img
                  src={ImageMail}
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "20px",
                  }}
                ></img>
                <b>Email: </b>brahmakumarisdhanori@gmail.com
              </h5>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15126.024027645308!2d73.905853!3d18.5962969!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c6dc815482bb%3A0xde60e952c51cd62b!2sBRAHMA%20KUMARIS%20PUNE!5e0!3m2!1sen!2sin!4v1705745765459!5m2!1sen!2sin"
              width="100%"
              height="300"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Responsive google Map"
            />
          </Card.Body>
        </Card>
      </CardGroup>

      <CardGroup className={`${cssClasses.secondBlock}`}>
        <Card>
          <Card.Body
            style={
              {
                // textAlign: "center",
              }
            }
            className={cssClasses.contactUsFormWrapper}
          >
            <Card.Text style={{ marginTop: "40px" }} as={"div"}>
              <h3>
                <b>Contact US</b>
              </h3>

              <Form style={{}}>
                <Form.Group className={cssClasses.contactUsInput}>
                  <Form.Control
                    type="name"
                    placeholder="Name"
                    value={values.name}
                    onChange={(e) => {
                      onInputChange(e.target.value, "name");
                      isNameValid(e.target.value);
                    }}
                  />
                  <Form.Text className={cssClasses.formErrorText}>
                    {errors.name}
                  </Form.Text>
                </Form.Group>

                <Form.Group className={cssClasses.contactUsInput}>
                  {/* <Form.Control
                    type="phone"
                    placeholder="Mobile No."
                    value={values.mobile}
                    onChange={(e) => onInputChange(e.target.value, "mobile")}
                  /> */}
                  <PhoneInput
                    country={"in"}
                    placeholder="Mobile No."
                    countryCodeEditable={false}
                    value={values.mobile}
                    onChange={(_phone) => {
                      onInputChange(_phone, "mobile");
                      isPhoneValid(_phone);
                    }}
                    inputClass={cssClasses.phoneInput}
                  />
                  <Form.Text className={cssClasses.formErrorText}>
                    {errors.mobile}
                  </Form.Text>
                </Form.Group>

                <Form.Group className={cssClasses.contactUsInput}>
                  <Form.Control
                    type="Email"
                    placeholder="Email"
                    value={values.email}
                    onChange={(e) => {
                      onInputChange(e.target.value, "email");
                      isEmailValid(e.target.value);
                    }}
                  />
                  <Form.Text className={cssClasses.formErrorText}>
                    {errors.email}
                  </Form.Text>
                </Form.Group>

                <Form.Group className={cssClasses.contactUsInput}>
                  <Form.Control
                    type="City"
                    placeholder="City"
                    value={values.city}
                    onChange={(e) => {
                      onInputChange(e.target.value, "city");
                      isCityValid(e.target.value);
                    }}
                  />
                  <Form.Text className={cssClasses.formErrorText}>
                    {errors.city}
                  </Form.Text>
                </Form.Group>

                <Form.Group className={cssClasses.contactUsInput}>
                  <Form.Control
                    type="State"
                    placeholder="State"
                    value={values.state}
                    onChange={(e) => {
                      onInputChange(e.target.value, "state");
                      isStateValid(e.target.value);
                    }}
                  />
                  <Form.Text className={cssClasses.formErrorText}>
                    {errors.state}
                  </Form.Text>
                </Form.Group>

                <Form.Group className={cssClasses.contactUsInput}>
                  <Form.Select
                    value={values.queryType}
                    placeholder="Select Query"
                    onChange={(e) => {
                      onInputChange(e.target.value, "queryType");
                      isQueryValid(e.target.value);
                    }}
                  >
                    <option>Select Query</option>
                    <option value={"Queries"}>Queries</option>
                    <option value={"Center Near Me"}>Center Near Me</option>
                    <option value={"Contribution"}>Contribution</option>
                    <option value={"Learn Meditation"}>Learn Meditation</option>
                    <option value={"Feedback"}>Feedback</option>
                    <option value={"Suggestions"}>Suggestions</option>
                  </Form.Select>

                  <Form.Text className={cssClasses.formErrorText}>
                    {errors.queryType}
                  </Form.Text>
                </Form.Group>

                <Form.Group className={cssClasses.contactUsInput}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Message"
                    value={values.message}
                    onChange={(e) => {
                      onInputChange(e.target.value, "message");
                      isMessageValid(e.target.value);
                    }}
                    onInput={(e) =>
                      (e.target.value = e.target.value?.slice(0, 200))
                    }
                  />
                  <Form.Text className={cssClasses.formErrorText}>
                    {errors.message}
                  </Form.Text>
                </Form.Group>

                <Button
                  className="App-form-button"
                  variant="primary"
                  // type="submit"
                  onClick={() => submitForm()}
                  disabled={buttonIsDisable}
                >
                  Submit {isLoading && <Spinner style={buttonStyle} />}
                </Button>
              </Form>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title style={{ marginBottom: "30px" }}>
              Address:-
              <p>
                Brahma Kumaris Survey No. 84/5,Near Trimurti lawns,Pawarvasti
                Lohegaon,Pune,Maharashtra 411047.
              </p>{" "}
            </Card.Title>
            <Card.Text>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.5528429288606!2d73.94716817501501!3d18.594187682513734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c6a65cd6e233%3A0xf1c851af90f80468!2sBrahma%20kumaris%20Lohegaon%20Wagoli%20Pune!5e0!3m2!1sen!2sin!4v1707812071703!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Responsive google Map"
              ></iframe>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title style={{ marginBottom: "30px" }}>
              Address:-
              <p>
                Brahma Kumaris Fitness MAntra Gym,Road No.3 adarsh colony,Tingre
                Nagar,Pune,Maharashtra 411015.
              </p>
            </Card.Title>
            <Card.Text>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.9499502392987!2d73.88735927501457!3d18.576295032528115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c0d5f6184dcb%3A0x8e8885f2ebb9693!2sFitness%20Mantras!5e0!3m2!1sen!2sin!4v1707812348470!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Responsive google Map"
              ></iframe>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default ContactUs;

const buttonStyle = {
  width: "13px",
  height: "13px",
  borderWidth: "1.5px",
  marginLeft: "4px",
};
