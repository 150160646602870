import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import cssClasses from "./Gallery.module.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import gallery1Img from "../../../../assets/images/gallery1.png";
import gallery2Img from "../../../../assets/images/gallery2.png";
import gallery3Img from "../../../../assets/images/gallery3.png";
import gallery5Img from "../../../../assets/images/gallery5.png";
import gallery6Img from "../../../../assets/images/gallery6.png";
import gallery7Img from "../../../../assets/images/gallery7.png";
import gallery8Img from "../../../../assets/images/gallery8.png";
import gallery10Img from "../../../../assets/images/gallery10.png";
import gallery11Img from "../../../../assets/images/gallery11.png";
import gallery12Img from "../../../../assets/images/gallery12.png";
import gallery13Img from "../../../../assets/images/gallery13.png";
import bgImg from "../../../../assets/images/bg.jpg";

function Gallery() {
  const Campus = [gallery12Img, gallery1Img, gallery2Img, gallery3Img];
  const MeditationRetreats = [gallery5Img, gallery6Img, gallery7Img, gallery8Img];
  const ConferenceWorkshop = [gallery10Img, gallery11Img, gallery12Img, gallery13Img];
  const WithSeniors=[bgImg];

  const ServingTheNation = [gallery12Img, gallery1Img, gallery2Img, gallery3Img];
  const ArtCulture = [gallery5Img, gallery6Img, gallery7Img, gallery8Img];
  const ServingTheNature = [gallery10Img, gallery11Img, gallery12Img, gallery13Img];
  const EminentPersonalities=[];

  const [photoUrlForModal, setPhotoUrlForModal] = useState(null);

  return (
    <>
      <div className={`content-container ${cssClasses.galleryWrapper}`}>
        <h1 style={{ marginBottom: "50px", textAlign: "center" }}>
          Breathtaking Landscapes & Intimate Moment
        </h1>
        <Tabs defaultActiveKey="All-Photos">
          <Tab eventKey="All-Photos" title="All Photos">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {[Campus, MeditationRetreats, ConferenceWorkshop,WithSeniors]
                .flat()
                .map((photoItem, photoIndex) => (
                  <div
                    class="App-pic"
                    key={`All-Photo-${photoIndex}`}
                    onClick={() => setPhotoUrlForModal(photoItem)}
                  >
                    <img src={photoItem} alt="" />
                  </div>
                ))}
            </div>
          </Tab>
          <Tab eventKey="Campus" title="Campus">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {Campus.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey="MeditationRetreats" title="Meditation Retreats">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {MeditationRetreats.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey="ConferenceWorkshop" title="Conference & Workshop">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {ConferenceWorkshop.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>

          <Tab eventKey="WithSeniors" title="With Seniors">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {WithSeniors.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>

        </Tabs>
      </div>

      <Modal
        size="lg"
        show={Boolean(photoUrlForModal)}
        onHide={() => setPhotoUrlForModal(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#9c245c" }}>
            <b>Photos</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={photoUrlForModal}
            alt=""
            className={cssClasses.showModalGalleryImage}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ background: "#78698a" }}
            variant="secondary"
            onClick={() => setPhotoUrlForModal(null)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      <div className={`content-container ${cssClasses.galleryWrapper}`}>
        <h1
          style={{
            marginBottom: "50px",
            marginTop: "100px",
            textAlign: "center",
          }}
        >
          Making A Difference:The Transformative Power Of Service
        </h1>
        <Tabs defaultActiveKey="All-Photos">
          <Tab eventKey="All-Photos" title="All Photos">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {[ServingTheNation, ArtCulture, ServingTheNature,EminentPersonalities]
                .flat()
                .map((photoItem, photoIndex) => (
                  <div
                    class="App-pic"
                    key={`All-Photo-${photoIndex}`}
                    onClick={() => setPhotoUrlForModal(photoItem)}
                  >
                    <img src={photoItem} alt="" />
                  </div>
                ))}
            </div>
          </Tab>
          <Tab eventKey="ServingTheNation" title="Serving The Nation">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {ServingTheNation.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey="ArtCulture" title="Art & Culture">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {ArtCulture.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey="ServingTheNature" title="Serving The Nature">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {ServingTheNature.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>

          <Tab eventKey="EminentPersonalities" title="Eminent Personalities">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {EminentPersonalities.map((photoItem, photoIndex) => (
                <div
                  class="App-pic"
                  key={`All-Photo-${photoIndex}`}
                  onClick={() => setPhotoUrlForModal(photoItem)}
                >
                  <img src={photoItem} alt="" />
                </div>
              ))}
            </div>
          </Tab>

        </Tabs>
      </div>

      <Modal
        size="lg"
        show={Boolean(photoUrlForModal)}
        onHide={() => setPhotoUrlForModal(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#9c245c" }}>
            <b>Photos</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={photoUrlForModal}
            alt=""
            className={cssClasses.showModalGalleryImage}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ background: "#78698a" }}
            variant="secondary"
            onClick={() => setPhotoUrlForModal(null)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Gallery;
