import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import cssClasses from "./Gallery.module.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import HthImage from "../../../../assets/images/Hth.jpeg"

function Events() {
  const CurrentEvents = [];
  const UpcomingEvents = [];
  const PastEvents = [HthImage];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <div className={`content-container ${cssClasses.galleryWrapper}`}>
        <Tabs defaultActiveKey="CurrentEvents">
          {/* <div class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}>
            {["Current Events, Upcoming Events, Past Events"]
              .flat()
              .map((photoItem, photoIndex) => (
                <div class="App-pic" key={`All-Photo-${photoIndex}`}>
                  <img src={photoItem} alt="" />
                </div>
              ))}
          </div> */}

          <Tab eventKey="CurrentEvents" title="Current Events">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {CurrentEvents?.length > 0 ? (
                CurrentEvents.map((photoItem, photoIndex) => (
                  <div class="App-pic" key={`All-Photo-${photoIndex}`}>
                    <img src={photoItem} alt="" />
                  </div>
                ))
              ) : (
                <h5>No event found!</h5>
              )}
            </div>
          </Tab>
          <Tab eventKey="UpcomingEvents" title="Upcoming Events">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {UpcomingEvents?.length > 0 ? (
                UpcomingEvents.map((photoItem, photoIndex) => (
                  <div class="App-pic" key={`All-Photo-${photoIndex}`}>
                    <img src={photoItem} alt="" />
                  </div>
                ))
              ) : (
                <h5>No event found!</h5>
              )}
            </div>
          </Tab>
          <Tab eventKey="PastEvents" title="Past Events">
            <div
              class={`App-photo-gallery ${cssClasses.appPhotoGalleryWrapper}`}
            >
              {PastEvents?.length > 0 ? (
                PastEvents.map((photoItem, photoIndex) => (
                  <div class="App-pic" key={`All-Photo-${photoIndex}`}>
                    <img src={photoItem} alt="" />
                    {/*<Button
                      variant="primary"
                      onClick={handleShow}
                      style={{ marginTop: "20px", background: "#318c8b" }}
                    >
                      Register Here
                    </Button> */}
                  </div>
                ))
              ) : (
                <h5>No event found!</h5>
              )}
            </div>
          </Tab>
        </Tabs>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#9c245c" }}>
            <b>HIGHWAY TO HAPPINESS - BK SHIVANI</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridName">
              
                <Form.Control type="name" placeholder="Name" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
               
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridContactNo">
             
              <Form.Control placeholder="Enter Number" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress">
            
              <Form.Control placeholder="Address" />
            </Form.Group>

            
              <Form.Group  className="mb-3" controlId="formGridAddress">
                
                <Form.Control placeholder="City" />
              </Form.Group>

              <Form.Group  className="mb-3" controlId="formGridAddress">
                
                <Form.Select defaultValue="..">
                  <option>How did you get to now about this event?</option>
                  <option>WhatsApp Invite</option>
                  <option>Email Invite</option>
                  <option>Poster / Banner / Hoarding</option>
                  <option>Facebook / YouTube / Social Media Sites</option>
                  <option>Invite from BK centre</option>
                  <option>Invite from friends</option>
                  <option>Others</option>
                </Form.Select>
              </Form.Group>
            

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default Events;
