import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageWrapper from "./content/main/Pages/PageWrapper/PageWrapper";
import AboutUs from "./content/main/Pages/AboutUs/AboutUs";
import Gallery from "./content/main/Pages/Gallery/Gallery";
import Events from "./content/main/Pages/Events/Events";
import ContactUs from "./content/main/Pages/ContactUs/ContactUs";
import Home from "./content/main/Pages/Home/Home";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-phone-input-2/lib/style.css'

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <PageWrapper />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <AboutUs />,
        },
        {
          path: "/gallery",
          element: <Gallery />,
        },
        {
          path: "/contact",
          element: <ContactUs />,
        },

        {
          path: "/events",
          element: <Events />,
        },
      ],
    },
  ]);

  //return <div><Home></Home></div>;
  return (
    <>
      <RouterProvider router={router} />;
      <ToastContainer />
    </>
  );
}

export default App;
