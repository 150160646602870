import React from "react";
import cssClasses from "./PreHeader.module.css";
import mailIcon from "../../../../assets/images/mail-icon.png";
import callIcon from "../../../../assets/images/call-icon.png";

function PreHeader() {

  const mail = "brahmakumarisdhanori@gmail.com";

  function goToMail() {
    const _d = document.createElement("a");
    _d.href = `mailto:${mail}`;
    _d.click();
  }

  return (
    <div className="App-preheader">
      <div className={`content-container ${cssClasses.preHeaderContainer}`}>
        <b style={{ color: "white" }}>We are on Godly Service 24×7 !</b>
        <div style={{ color: "white", display: "flex", flexDirection: "row", gap: "1.5rem" }}>
          <div style={{ color: "white", display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: 'center', cursor: "pointer" }} onClick={goToMail}>
            <img
              src={mailIcon}
              style={{
                height: "20px",
                width: "30px",
              }}
              alt=""
            />
            <small>{mail}</small>
          </div>
          <div style={{ color: "white", display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: 'center', cursor: "pointer" }}>
            <img
              src={callIcon}
              style={{
                width: "30px",
                height: "20px",
              }}
              alt=""
            />
            <small>
              <b style={{ color: "#db304d" }}>+91</b> 8975213990
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreHeader;
